.footer {
  padding: 64px 0 40px;
  color: $color-white;
  background-color: $color-violet;

  @media (min-width: $desktop) {
    position: relative;
    padding: 89px 0 25px;
  }
}

.footer__inner {
  @include inner();

  &:before {
    @media (min-width: $desktop) {
      content: '';
      position: absolute;
      top: 44px;
      bottom: 20px;
      width: 700px;
      background-image: url('../images/bg-footer-co.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
    }
  }
}

.footer__row {
  @media (min-width: $tablet) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gap-half;
    margin-right: -$gap-half;
  }

  & + & {
    margin-top: 140px;

    @media (min-width: $desktop) {
      margin-top: 160px;
    }
  }
}

.footer__logo {
  margin-bottom: 80px;

  @media (min-width: $tablet) {
    flex-basis: 100%;
    padding: 0 $gap-half;
    //margin-bottom: 0;
  }

  @media (min-width: $desktop) {
    flex-basis: 50%;
    margin-bottom: 0;
  }
}

.footer__contacts {
  @media (min-width: $tablet) {
    flex-basis: 50%;
    padding: 0 $gap-half;
  }

  @media (min-width: $desktop) {
    flex-basis: 25%;
  }
}

.footer__title {
  display: block;
  margin-bottom: 32px;
  font-family: $font-title;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  opacity: .6;

  @media (min-width: $desktop) {
    opacity: 1;
  }
}

.footer__follow {
  margin-top: 75px;

  @media (min-width: $tablet) {
    margin-top: 0;
    flex-basis: 50%;
    padding: 0 $gap-half;
  }

  @media (min-width: $desktop) {
    flex-basis: 25%;
  }
}

.footer__privacy {
  @media (min-width: $tablet) {
    flex-basis: 50%;
    order: 1;
    padding: 0 $gap-half;
  }
}

.footer__copyright {
  margin-top: 16px;

  @media (min-width: $tablet) {
    flex-basis: 50%;
    padding: 0 $gap-half;
    margin-top: 0;
  }
}

.copyright {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: .6;
}

.footer__scroll-btn {
  display: none;

  @media (min-width: $desktop-big) {
    display: flex;
    justify-content: flex-end;
  }
}

.footer__chat-btn {
  position: fixed;
  bottom: 50px;
  z-index: 50;
  display: flex;
  width: 64px;
  height: 59px;
  background-image: url('../images/icons/icon-dialog.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateX(100%);
}
