.offer {
  padding: 100px 0;

  @media (min-width: $desktop) {
    padding: 155px 0 165px;
  }
}

.offer__inner {
  @include inner();
}

.offer__intro {
  margin-bottom: 24px;

  @media (min-width: $desktop) {
    margin-bottom: 32px;
  }
}

.offer__slogan {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba($color-violet, .7);

  @media (min-width: $desktop) {
    font-size: 16px;
  }
}

.offer__row {
  @media (min-width: $tablet) {
    display: flex;
    margin-left: -$gap-half;
    margin-right: -$gap-half;
  }
}

.offer__head {
  margin-bottom: 40px;

  @media (min-width: $tablet) {
    flex-basis: 50%;
    padding: 0 $gap-half;
    margin-bottom: 0;
  }

  @media (min-width: $tablet-big) {
    flex-basis: 33.33%;
  }

  @media (min-width: $desktop) {
    flex-basis: 50%;
  }
}

.offer__title {
  font-family: $font-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;

  @media (min-width: $tablet) {
    position: sticky;
    top: 32px;
  }

  @media (min-width: $desktop) {
    padding-right: 95px;
    font-weight: 700;
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }

  @media (min-width: $desktop-big) {
    padding-right: 150px;
  }
}

.offer__body {
  @media (min-width: $tablet) {
    flex-basis: 50%;
    padding: 0 $gap-half;
  }

  @media (min-width: $tablet-big) {
    flex-basis: 66.66%;
  }

  @media (min-width: $desktop) {
    flex-basis: 50%;
  }
}

.offer__list {
  list-style: none;

  @media (min-width: $tablet-big) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gap-half;
    margin-right: -$gap-half;
  }
}

.offer__item {
  margin-top: 32px;
  opacity: .15;

  @media (min-width: $tablet-big) {
    flex-basis: 50%;
    padding: 0 $gap-half;
    margin-top: 40px;
  }

  @media (min-width: $desktop) {
    margin-top: 80px;
  }

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2) {
    @media (min-width: $tablet-big) {
      margin-top: 0;
    }
  }
}

.offer__icon {
  display: block;
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.offer__caption {
  font-family: $font-title;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;

  & + .offer__text {
    margin-top: 16px;

    @media (min-width: $desktop) {
      margin-top: 24px;
    }
  }
}

.offer__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.005em;
  opacity: .8;

  @media (min-width: $desktop) {
    font-size: 16px;
  }
}
