.step {
  display: none;
  height: 100%;

  &:first-child {
    display: block;
  }
}

.step__wrap {
  @media (min-width: $tablet) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.step__count {
  display: block;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: .7;
}

.step__title {
  font-family: $font-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;

  & + .step__text{
    margin-top: 16px;
  }
}

.step__text {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
  opacity: .7;
}

.step__btn {
  margin-top: 32px;

  @media (min-width: $tablet) {
    margin-top: auto;
  }
}
