* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html {
  height: 100%;
}

body {
  font-family: $font;
  color: $color-violet;
}

main,
article,
section,
footer,
header,
aside,
canvas {
  display: block;
}

button,
input,
select {
  font-family: inherit;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  font-size: inherit;
  font-weight: inherit;
}

img {
  max-width: 100%;
  display: block;
}

svg {
  max-width: 100%;
  display: inline-block;
}

a {
  color: inherit;
}

.overflow-wrap {
  //overflow: hidden;
  contain: paint;
}
