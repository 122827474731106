.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  padding: 24px 0;
}

.header__inner {
  @include inner();
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  img {
    height: 30px;

    @media (min-width: $mobile-client) {
      height: 34px;
    }
  }
}
