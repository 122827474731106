.lead {
  display: flex;
  justify-content: center;
  //background-color: #83999c;
  color: $color-white;
  overflow: hidden;
}

.lead__wrap {
  position: relative;
  flex-shrink: 0;
  max-width: 1920px;
  width: 100%;
  min-height: 800px;
  background-color: #83999c;

  @media (min-width: $desktop) {
    min-height: initial;
  }
}

.lead__video-wrap {
  position: relative;

  @media (min-width: $desktop) {
    padding-top: 56.25%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
  }
}

.lead__video {
  display: block;
  height: 800px;
  transform: translateX(-25%);

  @media (min-width: $tablet) {
    transform: translateX(0);
  }

  @media (min-width: $desktop) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.lead__content-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px 0;

  @media (min-width: $desktop) {
    padding: 55px 0 115px;
  }
}

.lead__inner {
  @include inner();
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lead__slogan {
  width: 255px;
  margin-bottom: auto;
  font-family: $font-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;

  @media (min-width: $desktop) {
    width: 300px;
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }
}

.lead__title {
  width: 270px;
  font-family: $font-title;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;

  @media (min-width: $desktop) {
    width: 460px;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }

  & + .lead__text {
    margin-top: 16px;
  }
}

.lead__text {
  max-width: 400px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: .7;


  @media (min-width: $desktop) {
    max-width: 685px;
    font-size: 16px;
    letter-spacing: -0.005em;
  }
}

.lead__btn {
  margin-top: 32px;

  @media (min-width: $desktop) {
    margin-top: 40px;
  }
}
