.service-accordion__head {
  position: relative;
  width: 100%;
  padding: 20px 0;
  font-family: $font-title;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: $color-violet;
  letter-spacing: -0.01em;

  @media (min-width: $desktop) {
    width: auto;
    padding: 23px 0;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }

  &.open {
    &:after {
      transform: rotate(180deg);
    }

    &:before {
      transform: rotate(90deg);
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    width: 22px;
    height: 2px;
    margin: auto;
    border-radius: 1px;
    background-color: $color-violet;

    @media (min-width: $desktop) {
      content: normal;
    }
  }

  &:before {
    transition: all .15s .15s linear;
  }

  &:after {
    transition: all .3s linear;

  }
}

.service-accordion__body {
  display: none;
  padding-bottom: 32px;

  @media (min-width: $desktop) {
    width: 66.66%;
    padding-bottom: 40px;
  }

  @media (min-width: $desktop-big) {
    width: 53%;
  }
}

.service-accordion__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: .7;

  p, ul {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.service-accordion__btn {
  margin-top: 32px;

  @media (min-width: $desktop) {
    margin-top: 40px;
  }
}
