.case__card {
  position: relative;
  padding-top: 373px;
}

.case__card-content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: rgba($color-white, .6);
  border-radius: 24px;

  @media (min-width: $desktop) {
    padding: 32px;
  }
}

.case__image {
  width: 267px;
  height: 120px;
  margin: 50px auto 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.case__info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.case__name {
  font-family: $font-title;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.case__duration {
  margin-top: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;

  span {
    display: block;
    margin-top: 4px;
  }
}

.case__grow {
  margin-top: 24px;
  padding-left: 24px;

  @media (min-width: $desktop) {
    margin-top: 32px;
    padding-left: 32px;
  }
}

.case__num {
  display: inline-flex;
  align-items: flex-start;
  font-family: $font-title;
  font-weight: 700;
  font-size: 46px;
  line-height: 48px;
  letter-spacing: -0.03em;

  @media (min-width: $desktop) {
    font-size: 64px;
    line-height: 64px;
    letter-spacing: -0.03em;
  }

  &:after {
    content: '';
    display: block;
    width: 21px;
    height: 21px;
    margin-left: 8px;
    background-image: url('../images/icons/icon-arrow-grow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: $desktop) {
      width: 24px;
      height: 24px;
    }
  }
}

.case__num-desc {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: .7;

  @media (min-width: $desktop) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}
