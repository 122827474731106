.order {
  position: relative;
  z-index: 1;
  padding: 40px 0;
  margin-top: -24px;
  margin-bottom: -24px;
  background-color: $color-white;
  border-radius: 24px;

  @media (min-width: $desktop) {
    padding: 50px 0 106px;
  }
}

.order__inner {
  @include inner();
}

.order__head {
  margin-bottom: 40px;
}

.order__title {
  display: flex;
  align-items: center;
  font-family: $font-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;

  & + .order__text {
    margin-top: 16px;
  }
}

.order__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: .7;
}
