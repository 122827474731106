/* inter-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/inter-v12-latin-500.woff2") format("woff2"), url("../fonts/inter-v12-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/inter-v12-latin-600.woff2") format("woff2"), url("../fonts/inter-v12-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/manrope-v13-latin-500.woff2") format("woff2"), url("../fonts/manrope-v13-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/manrope-v13-latin-600.woff2") format("woff2"), url("../fonts/manrope-v13-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/manrope-v13-latin-700.woff2") format("woff2"), url("../fonts/manrope-v13-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;
  color: #1B1464;
}

main,
article,
section,
footer,
header,
aside,
canvas {
  display: block;
}

button,
input,
select {
  font-family: inherit;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  font-size: inherit;
  font-weight: inherit;
}

img {
  max-width: 100%;
  display: block;
}

svg {
  max-width: 100%;
  display: inline-block;
}

a {
  color: inherit;
}

.overflow-wrap {
  contain: paint;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-weight: 400;
  font-size: 18px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid rgba(27, 20, 100, 0.1);
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 12px 0;
}

.select2-results__option {
  text-align: center;
  padding: 5px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #1B1464;
  user-select: none;
  -webkit-user-select: none;
}
@media (min-width: 960px) {
  .select2-results__option {
    padding: 0 20px;
  }
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(27, 20, 100, 0.3);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  flex-grow: 1;
  padding: 8px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(27, 20, 100, 0.7);
}
@media (min-width: 670px) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-top: 0;
    text-align: center;
  }
}
@media (min-width: 1280px) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: rgba(27, 20, 100, 0.7);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 22px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 5px;
  width: 6px;
  background-image: url("../images/icons/icon-circle-arrow-btn.svg");
  margin-left: -3px;
  margin-top: -3px;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: rotate(180deg);
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgba(27, 20, 100, 0.3);
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #1B1464;
  background-color: rgba(27, 20, 100, 0.1);
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.ui-kit {
  padding: 100px 0;
  background: #EBEAEA;
}

.ui-kit__btn {
  margin: 20px 0;
}

.ui-kit__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.btn {
  display: inline-block;
  padding: 16px 24px;
  white-space: nowrap;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  letter-spacing: -0.005em;
  border-radius: 32px;
  transition: all 0.3s;
}
.btn svg {
  width: 22px;
  height: 12px;
  margin-left: 10px;
  transition: all 0.3s;
}

.btn--full {
  width: 100%;
}
@media (min-width: 480px) {
  .btn--full {
    width: initial;
  }
}

.btn--violet {
  color: #fff;
  background-color: #1B1464;
}
.btn--violet svg {
  fill: #fff;
}
.btn--violet:hover {
  color: #1B1464;
  background-color: #fff;
}
.btn--violet:hover svg {
  fill: #1B1464;
}

.btn--green {
  color: #1B1464;
  background-color: #3FFF96;
}
.btn--green svg {
  fill: #1B1464;
}
.btn--green:hover {
  color: #3FFF96;
  background-color: #1B1464;
}
.btn--green:hover svg {
  fill: #3FFF96;
}

.btn--second {
  padding: 7px 22px;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #1B1464;
}
.btn--second:hover {
  background-color: #1B1464;
  color: #fff;
}
.btn--second:hover svg {
  fill: #fff;
}
.btn--second svg {
  fill: #1B1464;
}

.btn--mini {
  padding: 3px 11px;
  font-size: 14px;
}

.btn--header {
  padding: 7px;
  font-size: 14px;
  line-height: 24px;
}
@media (min-width: 393px) {
  .btn--header {
    padding: 7px 22px;
  }
}
@media (min-width: 1280px) {
  .btn--header {
    padding: 9px 22px;
    font-size: 18px;
  }
}

.btn--form {
  width: 100%;
}
@media (min-width: 670px) {
  .btn--form {
    width: 100%;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  padding: 24px 0;
}

.header__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo img {
  height: 30px;
}
@media (min-width: 393px) {
  .header__logo img {
    height: 34px;
  }
}

.promo {
  position: relative;
  background-color: #3FFF96;
  overflow: hidden;
}

.promo__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  position: relative;
}

.promo__wrap {
  position: relative;
  z-index: 2;
  padding-top: 100px;
}
@media (min-width: 960px) {
  .promo__wrap {
    padding: 160px 0;
  }
}
@media (min-width: 1280px) {
  .promo__wrap {
    padding: 260px 0 200px;
  }
}
@media (min-width: 1680px) {
  .promo__wrap {
    padding: 260px 0;
  }
}

@media (min-width: 960px) {
  .promo__info {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .promo__info {
    width: 50%;
  }
}

.promo__title {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}
@media (min-width: 1280px) {
  .promo__title {
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }
}
.promo__title > div {
  display: flex;
  align-items: center;
}
.promo__title + .promo__text {
  margin-top: 16px;
}
.promo__title span {
  display: block;
}

.promo__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.6;
}
@media (min-width: 1280px) {
  .promo__text {
    padding-right: 210px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

.promo__btn {
  display: none;
}
@media (min-width: 960px) {
  .promo__btn {
    display: block;
    margin-top: 90px;
  }
}

.promo__btn-mobile {
  margin-top: 80px;
  padding-bottom: 40px;
  text-align: center;
}
@media (min-width: 960px) {
  .promo__btn-mobile {
    display: none;
  }
}

.promo__bg-wrap {
  display: flex;
  justify-content: center;
}
@media (min-width: 960px) {
  .promo__bg-wrap {
    display: block;
  }
}

.promo__bg {
  flex-shrink: 0;
  top: 0;
  right: 0;
  width: 585px;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transform: translateX(-10%);
  margin: 0 auto;
  overflow: hidden;
}
@media (min-width: 960px) {
  .promo__bg {
    position: absolute;
    width: 90%;
    margin: auto;
    transform: translateX(15%);
  }
}

.pics {
  display: inline-flex;
}

.pics--white .pics__item {
  box-shadow: inset 0 0 0 4px #fff;
}

.pics__item {
  position: relative;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  margin-left: -20px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 4px #3FFF96;
  overflow: hidden;
}
.pics__item:first-child {
  margin-left: 0;
}
.pics__item:nth-child(1) {
  z-index: 1;
}
.pics__item:nth-child(2) {
  z-index: 2;
}
.pics__item:nth-child(3) {
  z-index: 3;
}
.pics__item:nth-child(4) {
  z-index: 4;
}
.pics__item:nth-child(4) {
  z-index: 5;
}

.promo__title .pics {
  width: 0;
  opacity: 0;
  margin-right: 0;
  transition: all 3s;
}
.promo__title .pics.visible {
  width: auto;
  opacity: 1;
  margin-right: 16px;
}
.order__title .pics {
  margin-left: 16px;
}

.video-bg {
  width: 99.9%;
}

.video-bg__wrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.video-bg__wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-image: url("../images/promo-mask.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
}
.video-bg__wrap:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  top: 0;
  left: -1px;
  background-color: #3FFF96;
}

.video-bg__video {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: 30px 0 0 30px;
}

.offer {
  padding: 100px 0;
}
@media (min-width: 1280px) {
  .offer {
    padding: 155px 0 165px;
  }
}

.offer__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.offer__intro {
  margin-bottom: 24px;
}
@media (min-width: 1280px) {
  .offer__intro {
    margin-bottom: 32px;
  }
}

.offer__slogan {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(27, 20, 100, 0.7);
}
@media (min-width: 1280px) {
  .offer__slogan {
    font-size: 16px;
  }
}

@media (min-width: 670px) {
  .offer__row {
    display: flex;
    margin-left: -16px;
    margin-right: -16px;
  }
}

.offer__head {
  margin-bottom: 40px;
}
@media (min-width: 670px) {
  .offer__head {
    flex-basis: 50%;
    padding: 0 16px;
    margin-bottom: 0;
  }
}
@media (min-width: 960px) {
  .offer__head {
    flex-basis: 33.33%;
  }
}
@media (min-width: 1280px) {
  .offer__head {
    flex-basis: 50%;
  }
}

.offer__title {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}
@media (min-width: 670px) {
  .offer__title {
    position: sticky;
    top: 32px;
  }
}
@media (min-width: 1280px) {
  .offer__title {
    padding-right: 95px;
    font-weight: 700;
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }
}
@media (min-width: 1680px) {
  .offer__title {
    padding-right: 150px;
  }
}

@media (min-width: 670px) {
  .offer__body {
    flex-basis: 50%;
    padding: 0 16px;
  }
}
@media (min-width: 960px) {
  .offer__body {
    flex-basis: 66.66%;
  }
}
@media (min-width: 1280px) {
  .offer__body {
    flex-basis: 50%;
  }
}

.offer__list {
  list-style: none;
}
@media (min-width: 960px) {
  .offer__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
  }
}

.offer__item {
  margin-top: 32px;
  opacity: 0.15;
}
@media (min-width: 960px) {
  .offer__item {
    flex-basis: 50%;
    padding: 0 16px;
    margin-top: 40px;
  }
}
@media (min-width: 1280px) {
  .offer__item {
    margin-top: 80px;
  }
}
.offer__item:first-child {
  margin-top: 0;
}
@media (min-width: 960px) {
  .offer__item:nth-child(2) {
    margin-top: 0;
  }
}

.offer__icon {
  display: block;
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.offer__caption {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.offer__caption + .offer__text {
  margin-top: 16px;
}
@media (min-width: 1280px) {
  .offer__caption + .offer__text {
    margin-top: 24px;
  }
}

.offer__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.005em;
  opacity: 0.8;
}
@media (min-width: 1280px) {
  .offer__text {
    font-size: 16px;
  }
}

.goals__item {
  padding: 24px 0;
  background: linear-gradient(106.57deg, #D6FAEA 39.06%, #8AFFC5 100%);
  border: 1px solid rgba(27, 20, 100, 0.3);
  border-radius: 24px;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .goals__item {
    padding: 32px 0;
  }
}
@media (min-width: 1280px) and (min-height: 800px) {
  .goals__item {
    position: sticky;
    top: 0;
    padding-bottom: 90px;
    border-radius: 24px 24px 0 0;
  }
}
.goals__item + .goals__item {
  margin-top: 24px;
}
@media (min-width: 1280px) and (min-height: 800px) {
  .goals__item + .goals__item {
    margin-top: -24px;
  }
}
@media (min-width: 1280px) and (min-height: 700px) {
  .goals__item:nth-child(2) {
    top: 160px;
  }
}
@media (min-width: 1280px) and (min-height: 700px) {
  .goals__item:nth-child(3) {
    top: 290px;
  }
}
@media (min-width: 1280px) and (min-height: 700px) {
  .goals__item:nth-child(4) {
    top: 420px;
  }
}

.goals__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  position: relative;
}
.goals__inner:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding-top: 49.5%;
  background-image: url("../images/bg-cco.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}
@media (min-width: 1280px) {
  .goals__inner:before {
    width: 1680px;
    height: 832px;
    padding-top: 0;
    transform: translate(-50%, -38%) scale(1);
  }
}

.goals__wrap {
  position: relative;
}

.goals__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}
@media (min-width: 1280px) {
  .goals__title {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}
.goals__title + .goals__caption {
  margin-top: 24px;
}

.goals__caption {
  margin: 16px 0 40px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}
@media (min-width: 1280px) {
  .goals__caption {
    margin-bottom: 48px;
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }
}
.goals__caption sup {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}
@media (min-width: 1280px) {
  .goals__caption sup {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

@media (min-width: 670px) {
  .goals__row {
    display: flex;
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media (min-width: 670px) {
  .goals__goal {
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 0 16px;
  }
}
.goals__goal strong {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
@media (min-width: 1280px) {
  .goals__goal strong {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
}
.goals__goal + .goals__solution {
  margin-top: 16px;
}
@media (min-width: 670px) {
  .goals__goal + .goals__solution {
    margin-top: 0;
  }
}

@media (min-width: 670px) {
  .goals__solution {
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 0 16px;
  }
}

.goals__btn {
  margin-top: 32px;
}
@media (min-width: 670px) {
  .goals__btn {
    margin-top: 40px;
  }
}

.goals__solution-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}
@media (min-width: 1280px) {
  .goals__solution-text {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}
.goals__solution-text p,
.goals__solution-text ul {
  margin-top: 16px;
}
.goals__solution-text p:first-child,
.goals__solution-text ul:first-child {
  margin-top: 0;
}

.services {
  padding: 100px 0;
}

.services__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.services__title {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}
@media (min-width: 1280px) {
  .services__title {
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }
}

.services__list {
  margin-top: 40px;
  list-style: none;
}
@media (min-width: 1280px) {
  .services__list {
    margin-top: 60px;
  }
}

.services__item {
  border-bottom: 1px solid rgba(27, 20, 100, 0.3);
}
.services__item:first-child {
  border-top: 1px solid rgba(27, 20, 100, 0.3);
}

.service-accordion__head {
  position: relative;
  width: 100%;
  padding: 20px 0;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #1B1464;
  letter-spacing: -0.01em;
}
@media (min-width: 1280px) {
  .service-accordion__head {
    width: auto;
    padding: 23px 0;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
}
.service-accordion__head.open:after {
  transform: rotate(180deg);
}
.service-accordion__head.open:before {
  transform: rotate(90deg);
}
.service-accordion__head:before, .service-accordion__head:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 22px;
  height: 2px;
  margin: auto;
  border-radius: 1px;
  background-color: #1B1464;
}
@media (min-width: 1280px) {
  .service-accordion__head:before, .service-accordion__head:after {
    content: normal;
  }
}
.service-accordion__head:before {
  transition: all 0.15s 0.15s linear;
}
.service-accordion__head:after {
  transition: all 0.3s linear;
}

.service-accordion__body {
  display: none;
  padding-bottom: 32px;
}
@media (min-width: 1280px) {
  .service-accordion__body {
    width: 66.66%;
    padding-bottom: 40px;
  }
}
@media (min-width: 1680px) {
  .service-accordion__body {
    width: 53%;
  }
}

.service-accordion__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}
.service-accordion__text p, .service-accordion__text ul {
  margin: 16px 0;
}
.service-accordion__text p:first-child, .service-accordion__text ul:first-child {
  margin-top: 0;
}
.service-accordion__text p:last-child, .service-accordion__text ul:last-child {
  margin-bottom: 0;
}

.service-accordion__btn {
  margin-top: 32px;
}
@media (min-width: 1280px) {
  .service-accordion__btn {
    margin-top: 40px;
  }
}

.lead {
  display: flex;
  justify-content: center;
  color: #fff;
  overflow: hidden;
}

.lead__wrap {
  position: relative;
  flex-shrink: 0;
  max-width: 1920px;
  width: 100%;
  min-height: 800px;
  background-color: #83999c;
}
@media (min-width: 1280px) {
  .lead__wrap {
    min-height: initial;
  }
}

.lead__video-wrap {
  position: relative;
}
@media (min-width: 1280px) {
  .lead__video-wrap {
    padding-top: 56.25%;
  }
}
.lead__video-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.lead__video {
  display: block;
  height: 800px;
  transform: translateX(-25%);
}
@media (min-width: 670px) {
  .lead__video {
    transform: translateX(0);
  }
}
@media (min-width: 1280px) {
  .lead__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.lead__content-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px 0;
}
@media (min-width: 1280px) {
  .lead__content-wrap {
    padding: 55px 0 115px;
  }
}

.lead__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lead__slogan {
  width: 255px;
  margin-bottom: auto;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}
@media (min-width: 1280px) {
  .lead__slogan {
    width: 300px;
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }
}

.lead__title {
  width: 270px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
@media (min-width: 1280px) {
  .lead__title {
    width: 460px;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
}
.lead__title + .lead__text {
  margin-top: 16px;
}

.lead__text {
  max-width: 400px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}
@media (min-width: 1280px) {
  .lead__text {
    max-width: 685px;
    font-size: 16px;
    letter-spacing: -0.005em;
  }
}

.lead__btn {
  margin-top: 32px;
}
@media (min-width: 1280px) {
  .lead__btn {
    margin-top: 40px;
  }
}

.steps {
  padding: 100px 0 20px;
}

.steps__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 670px) {
  .steps__inner {
    height: 500vh;
  }
}

.steps__area {
  position: relative;
  padding: 40px 16px;
  background: #D6FAEA;
  border-radius: 24px;
}
@media (min-width: 393px) {
  .steps__area {
    padding: 40px 32px;
  }
}
@media (min-width: 670px) {
  .steps__area {
    position: sticky;
    top: 20px;
  }
}
@media (min-width: 1280px) {
  .steps__area {
    padding: 64px 64px 126px;
  }
}

.steps__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(27, 20, 100, 0.7);
}
@media (min-width: 1280px) {
  .steps__title {
    margin-bottom: 40px;
  }
}

@media (min-width: 670px) {
  .steps__row {
    display: flex;
  }
}

@media (min-width: 670px) {
  .steps__visual {
    flex-basis: 50%;
    padding: 0 16px;
  }
}
@media (min-width: 1280px) {
  .steps__visual {
    padding-left: 30px;
  }
}

.steps__content {
  position: relative;
  margin-top: 40px;
}
@media (min-width: 670px) {
  .steps__content {
    position: static;
    flex-basis: 50%;
    padding: 0 16px;
    margin-top: 0;
  }
}

.steps__next {
  position: absolute;
  right: 0;
  top: 0;
  color: #1B1464;
}
@media (min-width: 670px) {
  .steps__next {
    display: none;
  }
}

.steps-chart {
  max-width: 540px;
}

.steps-chart__item {
  position: relative;
  padding-top: 25%;
  opacity: 0.3;
  transition: opacity 0.1s;
}
.steps-chart__item + .steps-chart__item {
  margin-top: -28px;
}
@media (min-width: 1280px) {
  .steps-chart__item + .steps-chart__item {
    margin-top: -45px;
  }
}
.steps-chart__item:nth-child(even):before {
  transform: scale(-1, 1);
}
.steps-chart__item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/ellipse-step.svg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.steps-chart__item.active {
  opacity: 1;
}

.steps-chart__text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.steps-chart__text span {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.step {
  display: none;
  height: 100%;
}
.step:first-child {
  display: block;
}

@media (min-width: 670px) {
  .step__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.step__count {
  display: block;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}

.step__title {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}
.step__title + .step__text {
  margin-top: 16px;
}

.step__text {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
  opacity: 0.7;
}

.step__btn {
  margin-top: 32px;
}
@media (min-width: 670px) {
  .step__btn {
    margin-top: auto;
  }
}

.footer {
  padding: 64px 0 40px;
  color: #fff;
  background-color: #1B1464;
}
@media (min-width: 1280px) {
  .footer {
    position: relative;
    padding: 89px 0 25px;
  }
}

.footer__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .footer__inner:before {
    content: "";
    position: absolute;
    top: 44px;
    bottom: 20px;
    width: 700px;
    background-image: url("../images/bg-footer-co.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
  }
}

@media (min-width: 670px) {
  .footer__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
  }
}
.footer__row + .footer__row {
  margin-top: 140px;
}
@media (min-width: 1280px) {
  .footer__row + .footer__row {
    margin-top: 160px;
  }
}

.footer__logo {
  margin-bottom: 80px;
}
@media (min-width: 670px) {
  .footer__logo {
    flex-basis: 100%;
    padding: 0 16px;
  }
}
@media (min-width: 1280px) {
  .footer__logo {
    flex-basis: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 670px) {
  .footer__contacts {
    flex-basis: 50%;
    padding: 0 16px;
  }
}
@media (min-width: 1280px) {
  .footer__contacts {
    flex-basis: 25%;
  }
}

.footer__title {
  display: block;
  margin-bottom: 32px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  opacity: 0.6;
}
@media (min-width: 1280px) {
  .footer__title {
    opacity: 1;
  }
}

.footer__follow {
  margin-top: 75px;
}
@media (min-width: 670px) {
  .footer__follow {
    margin-top: 0;
    flex-basis: 50%;
    padding: 0 16px;
  }
}
@media (min-width: 1280px) {
  .footer__follow {
    flex-basis: 25%;
  }
}

@media (min-width: 670px) {
  .footer__privacy {
    flex-basis: 50%;
    order: 1;
    padding: 0 16px;
  }
}

.footer__copyright {
  margin-top: 16px;
}
@media (min-width: 670px) {
  .footer__copyright {
    flex-basis: 50%;
    padding: 0 16px;
    margin-top: 0;
  }
}

.copyright {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
}

.footer__scroll-btn {
  display: none;
}
@media (min-width: 1680px) {
  .footer__scroll-btn {
    display: flex;
    justify-content: flex-end;
  }
}

.footer__chat-btn {
  position: fixed;
  bottom: 50px;
  z-index: 50;
  display: flex;
  width: 64px;
  height: 59px;
  background-image: url("../images/icons/icon-dialog.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateX(100%);
}

.contacts__list {
  list-style: none;
}

.contacts__item {
  margin-top: 16px;
}
@media (min-width: 1280px) {
  .contacts__item {
    margin-top: 8px;
  }
}
.contacts__item:first-child {
  margin-top: 0;
}

.contacts__link {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
@media (min-width: 1280px) {
  .contacts__link {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

@media (min-width: 1280px) {
  .contacts__link--tel {
    text-decoration: none;
  }
}

.contacts__info {
  margin-top: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
}
@media (min-width: 1280px) {
  .contacts__info {
    font-size: 16px;
    line-height: 22px;
  }
}
.contacts__info p,
.contacts__info ul {
  margin: 16px 0;
}
@media (min-width: 1280px) {
  .contacts__info p,
  .contacts__info ul {
    margin: 8px 0;
  }
}
.contacts__info p:first-child,
.contacts__info ul:first-child {
  margin-top: 0;
}
.contacts__info p:last-child,
.contacts__info ul:last-child {
  margin-bottom: 0;
}

.contacts__maps {
  display: inline-block;
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.6;
}
@media (min-width: 1280px) {
  .contacts__maps {
    margin-top: 16px;
    line-height: 18px;
  }
}

.social {
  display: inline-flex;
  list-style: none;
  align-items: center;
}

.social__item {
  margin-left: 16px;
}
@media (min-width: 1280px) {
  .social__item {
    margin-left: 8px;
  }
}
.social__item:first-child {
  margin-left: 0;
}

.social__link {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 1280px) {
  .social__link {
    width: 32px;
    height: 32px;
  }
}
.social__link span {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.privacy {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.privacy__item {
  margin-right: 24px;
}
.privacy__item:last-child {
  margin-right: 0;
}

.privacy__link {
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
}

.cases {
  padding: 85px 0 55px;
}
@media (min-width: 1280px) {
  .cases {
    padding: 165px 0;
  }
}

.cases__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  position: relative;
}
.cases__inner:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 1175px;
  height: 803px;
  background-image: url("../images/bg-cases-mobile.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: translate(-60%, -25%);
}
@media (min-width: 1280px) {
  .cases__inner:before {
    width: 1680px;
    height: 1264px;
    background-image: url("../images/bg-cases.svg");
    transform: translate(-50%, -35%);
  }
}

.cases__wrap {
  position: relative;
}

.cases__slider-wrap {
  margin-left: -24px;
  margin-right: -24px;
}
@media (min-width: 1280px) {
  .cases__slider-wrap {
    width: 116.66%;
  }
}

.cases__slider {
  margin-top: 40px;
}
@media (min-width: 670px) {
  .cases__slider {
    padding-left: 9px;
  }
}

.cases__item {
  padding: 0 24px;
}
@media (min-width: 670px) {
  .cases__item {
    padding: 0 15px;
  }
}

.cases__title {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}
@media (min-width: 1280px) {
  .cases__title {
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }
}

.cases__slider-next {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 32px;
  background-image: url("../images/icons/btn-arrow-slider.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 1280px) {
  .cases__slider-next {
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #3FFF96;
    transform: rotate(15deg) translateY(-50%);
    background-image: none;
  }
}
@media (min-width: 320px) and (max-width: 1279px) {
  .cases__slider-next span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
}
@media (min-width: 1280px) {
  .cases__slider-next span {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
  }
}

.case__card {
  position: relative;
  padding-top: 373px;
}

.case__card-content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 24px;
}
@media (min-width: 1280px) {
  .case__card-content {
    padding: 32px;
  }
}

.case__image {
  width: 267px;
  height: 120px;
  margin: 50px auto 0;
}
.case__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.case__info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.case__name {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.case__duration {
  margin-top: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}
.case__duration span {
  display: block;
  margin-top: 4px;
}

.case__grow {
  margin-top: 24px;
  padding-left: 24px;
}
@media (min-width: 1280px) {
  .case__grow {
    margin-top: 32px;
    padding-left: 32px;
  }
}

.case__num {
  display: inline-flex;
  align-items: flex-start;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 46px;
  line-height: 48px;
  letter-spacing: -0.03em;
}
@media (min-width: 1280px) {
  .case__num {
    font-size: 64px;
    line-height: 64px;
    letter-spacing: -0.03em;
  }
}
.case__num:after {
  content: "";
  display: block;
  width: 21px;
  height: 21px;
  margin-left: 8px;
  background-image: url("../images/icons/icon-arrow-grow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 1280px) {
  .case__num:after {
    width: 24px;
    height: 24px;
  }
}

.case__num-desc {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}
@media (min-width: 1280px) {
  .case__num-desc {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

.info {
  padding-top: 80px;
}
@media (min-width: 1280px) {
  .info {
    padding-top: 156px;
  }
}

.info__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 160px;
}
@media (min-width: 1280px) {
  .info__inner {
    padding-bottom: 250px;
  }
}
.info__inner:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 1179px;
  height: 709px;
  background-image: url("../images/bg-info.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: translate(-50%, 20%) scale(1);
}
@media (min-width: 1280px) {
  .info__inner:before {
    transform: translate(-50%, 20%) scale(1.8);
  }
}

@media (min-width: 960px) {
  .info__row {
    display: flex;
    margin-left: -16px;
    margin-right: -16px;
  }
}

.info__video {
  margin-left: -8px;
  margin-right: -8px;
}
@media (min-width: 960px) {
  .info__video {
    margin: 0;
    padding: 0 16px;
  }
}

.info__content {
  margin-top: 24px;
}
@media (min-width: 960px) {
  .info__content {
    margin: 0;
    padding: 0 16px;
  }
}

@media (min-width: 1280px) {
  .info__text {
    width: 77%;
  }
}
.info__text p, .info__text ul {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
@media (min-width: 1280px) {
  .info__text p, .info__text ul {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}
.info__text p:first-child, .info__text ul:first-child {
  margin-top: 0;
}
.info__text + .info__statistics {
  margin-top: 40px;
}
@media (min-width: 1280px) {
  .info__text + .info__statistics {
    margin-top: 166px;
  }
}

.statistics__head {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(27, 20, 100, 0.3);
}
@media (min-width: 1280px) {
  .statistics__head {
    padding-bottom: 40px;
  }
}

.statistics__head-key {
  display: block;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 46px;
  line-height: 48px;
  letter-spacing: -0.03em;
}
@media (min-width: 1280px) {
  .statistics__head-key {
    font-size: 64px;
    line-height: 80px;
  }
}

.statistics__head-value {
  display: block;
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}
@media (min-width: 1280px) {
  .statistics__head-value {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

.statistics__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -16px;
  margin-right: -16px;
}

.statistics__item {
  width: 40%;
  padding: 0 16px;
  margin-top: 24px;
}
@media (min-width: 1280px) {
  .statistics__item {
    margin-top: 40px;
  }
}

.statistics__key {
  display: block;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}

.statistics__value {
  display: block;
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}
@media (min-width: 1280px) {
  .statistics__value {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

.video {
  display: flex;
  justify-content: center;
  border-radius: 24px;
  overflow: hidden;
}
.video video {
  display: block;
  width: 100%;
}

.order {
  position: relative;
  z-index: 1;
  padding: 40px 0;
  margin-top: -24px;
  margin-bottom: -24px;
  background-color: #fff;
  border-radius: 24px;
}
@media (min-width: 1280px) {
  .order {
    padding: 50px 0 106px;
  }
}

.order__inner {
  max-width: 1552px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.order__head {
  margin-bottom: 40px;
}

.order__title {
  display: flex;
  align-items: center;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}
.order__title + .order__text {
  margin-top: 16px;
}

.order__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
}

.form {
  margin-top: 40px;
}
@media (min-width: 1280px) {
  .form {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 670px) {
  .form__field {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 1280px) {
  .form__field {
    margin-top: 32px;
    padding-right: 8px;
  }
}
.form__field + .form__field {
  margin-top: 32px;
}
@media (min-width: 1280px) {
  .form__field:nth-child(1), .form__field:nth-child(2) {
    flex-basis: 50%;
  }
}
@media (min-width: 1280px) {
  .form__field:nth-child(3) {
    flex-basis: 100%;
  }
}
@media (min-width: 1280px) {
  .form__field:nth-child(4) {
    flex-basis: 30%;
  }
}
@media (min-width: 1280px) {
  .form__field:nth-child(5) {
    flex-basis: 70%;
  }
}
@media (min-width: 1280px) {
  .form__field:nth-child(6) {
    flex-basis: 60%;
  }
}
@media (min-width: 1280px) {
  .form__field:nth-child(7) {
    flex-basis: 40%;
  }
}
@media (min-width: 1280px) {
  .form__field:nth-child(8) {
    flex-basis: 100%;
  }
}

.form__btn {
  flex-basis: 100%;
}

.form__label {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
@media (min-width: 670px) {
  .form__label {
    margin-right: 8px;
  }
}
@media (min-width: 1280px) {
  .form__label {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
}

.form__input-wrap {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
}
@media (min-width: 670px) {
  .form__input-wrap {
    flex-grow: 1;
    margin-top: 0;
  }
}
.form__input-error {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  color: #E20000;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.form__input {
  flex-grow: 1;
  padding: 8px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(27, 20, 100, 0.7);
  border-bottom: 1px solid rgba(27, 20, 100, 0.3);
}
@media (min-width: 670px) {
  .form__input {
    padding-top: 0;
    text-align: center;
  }
}
@media (min-width: 1280px) {
  .form__input {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}
.form__input::placeholder {
  color: rgba(27, 20, 100, 0.7);
}
.form__input.error ~ .form__input-error {
  display: block;
}

.form__btn {
  margin-top: 36px;
}

.form__input-text {
  margin-left: 16px;
}

.form__messages {
  display: flex;
  flex-direction: column;
}
.form__message {
  display: none;
  margin-top: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
}
.form__message.show {
  display: block;
}

.form__message--error {
  color: #E20000;
}

.form__message--success {
  color: #3FFF96;
}

.form__private {
  order: 2;
  margin-top: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.7;
}

.form__response {
  order: 1;
}