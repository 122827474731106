// Row, col
@mixin inner($width: $inner) {
  max-width: $inner + $gap-safe * 2;
  padding-left: $gap-safe;
  padding-right: $gap-safe;
  margin: 0 auto;
}

@mixin row-mixin($margin: 10px) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$margin;
  margin-right: -$margin;
}

@mixin col-mixin($padding: 10px) {
  padding-left: $padding;
  padding-right: $padding;
}

// Visibility
@mixin visually-hidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

// Fonts
@mixin h1() {
  // Inter
  font-size: 64px;
  line-height: 64px;
  font-weight: 600;
}

@mixin h2() {
  // Manrope
  font-size: 64px;
  line-height: 64px;
  font-weight: 600;
}

@mixin h3() {
  // Inter
  font-size: 64px;
  line-height: 64px;
  font-weight: 600;
}

@mixin h4() {
  // Inter
  font-size: 64px;
  line-height: 64px;
  font-weight: 600;
}

@mixin font-body-m() {
  // Inter
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.005em;
}

@mixin font-body-s() {
  // Inter
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: -0.005em;
}

@mixin font-note() {
  // Inter
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}
