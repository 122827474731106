.ui-kit {
  padding: 100px 0;
  background: #EBEAEA;
}

.ui-kit__btn {
  margin: 20px 0;
}

.ui-kit__inner {
  @include inner();
}
