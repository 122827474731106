.info {
  padding-top: 80px;

  @media (min-width: $desktop) {
    padding-top: 156px;
  }
}

.info__inner {
  @include inner();
  position: relative;
  padding-bottom: 160px;

  @media (min-width: $desktop) {
    padding-bottom: 250px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 1179px;
    height: 709px;
    background-image: url('../images/bg-info.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: translate(-50%, 20%) scale(1);

    @media (min-width: $desktop) {
      transform: translate(-50%, 20%) scale(1.8);
    }
  }
}

.info__row {
  @media (min-width: $tablet-big) {
    display: flex;
    margin-left: -$gap-half;
    margin-right: -$gap-half;
  }
}

.info__video {
  margin-left: -8px;
  margin-right: -8px;

  @media (min-width: $tablet-big) {
    margin: 0;
    padding: 0 $gap-half;
  }
}

.info__content {
  margin-top: 24px;

  @media (min-width: $tablet-big) {
    margin: 0;
    padding: 0 $gap-half;
  }
}

.info__text {
  @media (min-width: $desktop) {
    width: 77%;
  }

  p, ul {
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    @media (min-width: $desktop) {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.005em;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  & + .info__statistics {
    margin-top: 40px;

    @media (min-width: $desktop) {
      margin-top: 166px;
    }
  }
}
