.form {
  margin-top: 40px;

  @media (min-width: $desktop) {
    display: flex;
    flex-wrap: wrap;
  }
}

.form__field {
  @media (min-width: $tablet) {
    display: flex;
    align-items: center;
  }

  @media (min-width: $desktop) {
    margin-top: 32px;
    padding-right: 8px;
  }

  & + & {
    margin-top: 32px;
  }

  &:nth-child(1),
  &:nth-child(2) {
    @media (min-width: $desktop) {
      flex-basis: 50%;
    }
  }

  &:nth-child(3) {
    @media (min-width: $desktop) {
      flex-basis: 100%;
    }
  }

  &:nth-child(4) {
    @media (min-width: $desktop) {
      flex-basis: 30%;
    }
  }

  &:nth-child(5) {
    @media (min-width: $desktop) {
      flex-basis: 70%;
    }
  }

  &:nth-child(6) {
    @media (min-width: $desktop) {
      flex-basis: 60%;
    }
  }

  &:nth-child(7) {
    @media (min-width: $desktop) {
      flex-basis: 40%;
    }
  }

  &:nth-child(8) {
    @media (min-width: $desktop) {
      flex-basis: 100%;
    }
  }
}

.form__btn {
  flex-basis: 100%;
}

.form__label {
  font-family: $font-title;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;

  @media (min-width: $tablet) {
    margin-right: 8px;
  }

  @media (min-width: $desktop) {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
}

.form__input-wrap {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: 8px;

  @media (min-width: $tablet) {
    flex-grow: 1;
    margin-top: 0;
  }

  @media (min-width: $desktop) {

  }
}

.form__input-error {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  color: $color-red;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.form__input {
  flex-grow: 1;
  padding: 8px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba($color-violet, .7);
  border-bottom: 1px solid rgba($color-violet, .3);

  @media (min-width: $tablet) {
    padding-top: 0;
    text-align: center;
  }

  @media (min-width: $desktop) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }

  &::placeholder {
    color: rgba($color-violet, .7);
  }

  &.error ~ .form__input-error {
    display: block;
  }
}

.form__btn {
  margin-top: 36px;
}

.form__input-text {
  margin-left: 16px;
}

.form__messages {
  display: flex;
  flex-direction: column;

  @media screen {

  }
}

.form__message {
  display: none;
  margin-top: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;

  &.show {
    display: block;
  }
}

.form__message--error {
  color: $color-red;
}

.form__message--success {
  color: $color-green;
}

.form__private {
  order: 2;
  margin-top: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: .7;
}

.form__response {
  order: 1;
}
