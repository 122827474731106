.services {
  padding: 100px 0;
}

.services__inner {
  @include inner();
}


.services__title {
  font-family: $font-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;

  @media (min-width: $desktop) {
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }
}

.services__list {
  margin-top: 40px;
  list-style: none;

  @media (min-width: $desktop) {
    margin-top: 60px;
  }
}

.services__item {
  border-bottom: 1px solid rgba($color-violet, .3);

  &:first-child {
    border-top: 1px solid rgba($color-violet, .3);
  }
}
