.video-bg {
  width: 99.9%;
}

.video-bg__wrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: url('../images/promo-mask.svg');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    top: 0;
    left: -1px;
    background-color: $color-green;
  }
}

.video-bg__video {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: 30px 0 0 30px;
}
