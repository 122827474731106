.cases {
  padding: 85px 0 55px;

  @media (min-width: $desktop) {
    padding: 165px 0;
  }
}

.cases__inner {
  @include inner();
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 1175px;
    height: 803px;
    background-image: url('../images/bg-cases-mobile.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: translate(-60%, -25%);

    @media (min-width: $desktop) {
      width: 1680px;
      height: 1264px;
      background-image: url('../images/bg-cases.svg');
      transform: translate(-50%, -35%);
    }
  }
}

.cases__wrap {
  position: relative;
}

.cases__slider-wrap {
  margin-left: -24px;
  margin-right: -24px;

  @media (min-width: $desktop) {
    width: 116.66%;
  }
}

.cases__slider {
  margin-top: 40px;

  @media (min-width: $tablet) {
    padding-left: 9px;
  //  margin-left: -15px;
  //  margin-right: -15px;
  }
}

.cases__item {
  padding: 0 24px;

  @media (min-width: $tablet) {
    padding: 0 15px;
  }
}

.cases__title {
  font-family: $font-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;

  @media (min-width: $desktop) {
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }
}

.cases__slider-next {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 32px;
  background-image: url('../images/icons/btn-arrow-slider.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (min-width: $desktop) {
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: $color-green;
    transform: rotate(15deg) translateY(-50%);
    background-image: none;
  }

  span {
    @media (min-width: $mobile) and (max-width: $tablet-big-max) {
      @include visually-hidden();
    }

    @media (min-width: $desktop) {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
    }
  }
}
