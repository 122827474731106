.video {
  display: flex;
  justify-content: center;
  border-radius: 24px;
  overflow: hidden;

  video {
    display: block;
    width: 100%;
  }
}
