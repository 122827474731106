.btn {
  display: inline-block;
  padding: 16px 24px;
  white-space: nowrap;
  font-family: $font;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  letter-spacing: -0.005em;
  border-radius: 32px;
  transition: all .3s;

  svg {
    width: 22px;
    height: 12px;
    margin-left: 10px;
    transition: all .3s;
  }
}

.btn--full {
  width: 100%;

  @media (min-width: $mobile-big) {
    width: initial;
  }
}

.btn--violet {
  color: $color-white;
  background-color: $color-violet;

  svg {
    fill: $color-white;
  }

  &:hover {
    color: $color-violet;
    background-color: $color-white;

    svg {
      fill: $color-violet;
    }
  }
}

.btn--green {
  color: $color-violet;
  background-color: $color-green;

  svg {
    fill: $color-violet;
  }

  &:hover {
    color: $color-green;
    background-color: $color-violet;

    svg {
      fill: $color-green;
    }
  }
}

.btn--second {
  padding: 7px 22px;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid $color-violet;

  &:hover {
    background-color: $color-violet;
    color: $color-white;

    svg {
      fill: $color-white;
    }
  }

  svg {
    fill: $color-violet;
  }
}

.btn--mini {
  padding: 3px 11px;
  font-size: 14px;
}

.btn--header {
  padding: 7px;
  font-size: 14px;
  line-height: 24px;

  @media (min-width: $mobile-client) {
    padding: 7px 22px;
  }

  @media (min-width: $desktop) {
    padding: 9px 22px;
    font-size: 18px;
  }
}

.btn--form {
  width: 100%;

  @media (min-width: $tablet) {
    width: 100%;
  }
}
