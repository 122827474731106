.contacts__list {
  list-style: none;
}

.contacts__item {
  margin-top: 16px;

  @media (min-width: $desktop) {
    margin-top: 8px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.contacts__link {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;

  @media (min-width: $desktop) {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

.contacts__link--tel {
  @media (min-width: $desktop) {
    text-decoration: none;
  }
}

.contacts__info {
  margin-top: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;

  @media (min-width: $desktop) {
    font-size: 16px;
    line-height: 22px;
  }

  p,
  ul {
    margin: 16px 0;

    @media (min-width: $desktop) {
      margin: 8px 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contacts__maps {
  display: inline-block;
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  opacity: .6;

  @media (min-width: $desktop) {
    margin-top: 16px;
    line-height: 18px;
  }
}
