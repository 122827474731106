.social {
  display: inline-flex;
  list-style: none;
  align-items: center;
}

.social__item {
  margin-left: 16px;

  @media (min-width: $desktop) {
    margin-left: 8px;
  }

  &:first-child {
    margin-left: 0;
  }
}

.social__link {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (min-width: $desktop) {
    width: 32px;
    height: 32px;
  }

  span {
    @include visually-hidden();
  }
}


