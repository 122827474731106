.privacy {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.privacy__item {
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
}

.privacy__link {
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: .6;
}
