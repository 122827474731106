// Colors
$color-red: #E20000;
$color-violet: #1B1464;
$color-green: #3FFF96;
$color-green-light: #D6FAEA;
$color-lilac: #8C82F4;
$color-white: #fff;

// breakpoints
$mobile: 320px;
$mobile-client: 393px;
$mobile-big: 480px;
$tablet: 670px;
$tablet-big: 960px;
$desktop: 1280px;
$desktop-big: 1680px;

$mobile-max: $mobile-client - 1px;
$mobile-client-max: $mobile-big - 1px;
$mobile-big-max: $tablet - 1px;
$tablet-max: $tablet-big - 1px;
$tablet-big-max: $desktop - 1px;
$desktop-max: $desktop-big - 1px;

// indents
$gap: 32px;
$gap-half: calc($gap / 2);

$gap-safe: 24px;
$inner: 1504px;

  // fonts
$font: 'Inter', sans-serif;
$font-title: 'Manrope', sans-serif;
