.steps-chart {
  max-width: 540px;
}

.steps-chart__item {
  position: relative;
  padding-top: 25%;
  opacity: .3;
  transition: opacity .1s;

  & + & {
    margin-top: -28px;

    @media (min-width: $desktop) {
      margin-top: -45px;
    }
  }

  &:nth-child(even) {

    &:before {
      transform: scale(-1, 1)
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/ellipse-step.svg');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.active {
    opacity: 1;
  }
}

.steps-chart__text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  span {
    font-family: $font-title;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }
}
