.goals__item {
  $position-fix: 30px;
  $position-delta: 130px;

  padding: 24px 0;
  background: linear-gradient(106.57deg, #D6FAEA 39.06%, #8AFFC5 100%);
  border: 1px solid rgba($color-violet, .3);
  border-radius: 24px;
  overflow: hidden;

  @media (min-width: $desktop) {
    padding: 32px 0;
  }

  @media (min-width: $desktop) and (min-height: 800px) {
    position: sticky;
    top: 0;
    padding-bottom: 90px;
    border-radius: 24px 24px 0 0;
  }

  & + & {
    margin-top: 24px;

    @media (min-width: $desktop) and (min-height: 800px) {
      margin-top: -24px;
    }
  }

  &:nth-child(2) {
    @media (min-width: $desktop) and (min-height: 700px) {
      top: $position-fix + $position-delta;
    }
  }

  &:nth-child(3) {
    @media (min-width: $desktop) and (min-height: 700px) {
      top: $position-fix + $position-delta * 2;
    }
  }

  &:nth-child(4) {
    @media (min-width: $desktop) and (min-height: 700px) {
      top: $position-fix + $position-delta * 3;
    }
  }
}

.goals__inner {
  @include inner();
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding-top: 49.5%;
    background-image: url('../images/bg-cco.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);

    @media (min-width: $desktop) {
      width: 1680px;
      height: 832px;
      padding-top: 0;
      transform: translate(-50%, -38%) scale(1);
    }
  }
}

.goals__wrap {
  position: relative;
}

.goals__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: .7;

  @media (min-width: $desktop) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }

  & + .goals__caption {
    margin-top: 24px;
  }
}

.goals__caption {
  margin: 16px 0 40px;
  font-family: $font-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;

  @media (min-width: $desktop) {
    margin-bottom: 48px;
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }

  sup {
    font-family: $font;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    opacity: .7;

    @media (min-width: $desktop) {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.005em;
    }
  }
}

.goals__row {
  @media (min-width: $tablet) {
    display: flex;
    margin-left: -$gap-half;
    margin-right: -$gap-half;
  }
}

.goals__goal {
  @media (min-width: $tablet) {
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 0 $gap-half;
  }

  strong {
    font-family: $font-title;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;

    @media (min-width: $desktop) {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.015em;
    }
  }

  & + .goals__solution {
    margin-top: 16px;

    @media (min-width: $tablet) {
      margin-top: 0;
    }
  }
}

.goals__solution {
  @media (min-width: $tablet) {
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 0 $gap-half;
  }
}

.goals__btn {
  margin-top: 32px;

  @media (min-width: $tablet) {
    margin-top: 40px;
  }
}

.goals__solution-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: .7;

  @media (min-width: $desktop) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }

  p,
  ul {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}
