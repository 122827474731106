.steps {
  padding: 100px 0 20px;
}

.steps__inner {
  @include inner();
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: $tablet) {
    height: 500vh;
  }
}

.steps__area {
  position: relative;
  padding: 40px 16px;
  background: $color-green-light;
  border-radius: 24px;

  @media (min-width: $mobile-client) {
    padding: 40px 32px;
  }

  @media (min-width: $tablet) {
    position: sticky;
    top: 20px;
  }

  @media (min-width: $desktop) {
    padding: 64px 64px 126px;
  }
}

.steps__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba($color-violet, .7);

  @media (min-width: $desktop) {
    margin-bottom: 40px;
  }
}

.steps__row {
  @media (min-width: $tablet) {
    display: flex;
  }
}

.steps__visual {
  @media (min-width: $tablet) {
    flex-basis: 50%;
    padding: 0 $gap-half;
  }

  @media (min-width: $desktop) {
    padding-left: 30px;
  }
}

.steps__content {
  position: relative;
  margin-top: 40px;

  @media (min-width: $tablet) {
    position: static;
    flex-basis: 50%;
    padding: 0 $gap-half;
    margin-top: 0;
  }
}

.steps__next {
  position: absolute;
  right: 0;
  top: 0;
  color: $color-violet;

  @media (min-width: $tablet) {
    display: none;
  }
}
