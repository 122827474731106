.pics {
  display: inline-flex;
}

.pics--white {
  .pics__item {
    box-shadow: inset 0 0 0 4px $color-white;
  }
}

.pics__item {
  position: relative;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  margin-left: -20px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 4px $color-green;
  overflow: hidden;

  &:first-child {
    margin-left: 0;
  }

  &:nth-child(1) {
    z-index: 1;
  }

  &:nth-child(2) {
    z-index: 2;
  }

  &:nth-child(3) {
    z-index: 3;
  }

  &:nth-child(4) {
    z-index: 4;
  }

  &:nth-child(4) {
    z-index: 5;
  }
}

// State {
.pics {
  .promo__title & {
    width: 0;
    opacity: 0;
    margin-right: 0;
    transition: all 3s;

    &.visible {
      width: auto;
      opacity: 1;
      margin-right: 16px;
    }
  }

  .order__title & {
    margin-left: 16px;
  }
}
