.promo {
  position: relative;
  background-color: $color-green;
  overflow: hidden;
}

.promo__inner {
  @include inner();
  position: relative;
}

.promo__wrap {
  position: relative;
  z-index: 2;
  padding-top: 100px;

  @media (min-width: $tablet-big) {
    padding: 160px 0;
  }

  @media (min-width: $desktop) {
    padding: 260px 0 200px;
  }

  @media (min-width: $desktop-big) {
    padding: 260px 0;
  }
}

.promo__info {
  @media (min-width: $tablet-big) {
    width: 50%;
  }

  @media (min-width: $desktop) {
    width: 50%;
  }
}

.promo__title {
  font-family: $font-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;

  @media (min-width: $desktop) {
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -0.03em;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  & + .promo__text {
    margin-top: 16px;
  }

  span {
    display: block;
  }
}

.promo__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: .6;

  @media (min-width: $desktop) {
    padding-right: 210px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

.promo__btn {
  display: none;

  @media (min-width: $tablet-big) {
    display: block;
    margin-top: 90px;
  }
}

.promo__btn-mobile {
  margin-top: 80px;
  padding-bottom: 40px;
  text-align: center;

  @media (min-width: $tablet-big) {
    display: none;
  }
}

.promo__bg-wrap {
  display: flex;
  justify-content: center;

  @media (min-width: $tablet-big) {
    display: block;
  }
}

.promo__bg {
  flex-shrink: 0;
  top: 0;
  right: 0;
  width: 585px;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transform: translateX(-10%);
  margin: 0 auto;
  overflow: hidden;

  @media (min-width: $tablet-big) {
    position: absolute;
    width: 90%;
    margin: auto;
    transform: translateX(15%);
  }
}
