.statistics__head {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba($color-violet, .3);

  @media (min-width: $desktop) {
    padding-bottom: 40px;
  }
}

.statistics__head-key {
  display: block;
  font-family: $font-title;
  font-weight: 700;
  font-size: 46px;
  line-height: 48px;
  letter-spacing: -0.03em;

  @media (min-width: $desktop) {
    font-size: 64px;
    line-height: 80px;
  }
}

.statistics__head-value {
  display: block;
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;

  @media (min-width: $desktop) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}

.statistics__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -$gap-half;
  margin-right: -$gap-half;
}

.statistics__item {
  width: 40%;
  padding: 0 $gap-half;
  margin-top: 24px;

  @media (min-width: $desktop) {
    margin-top: 40px;
  }
}

.statistics__key {
  display: block;
  font-family: $font-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}

.statistics__value {
  display: block;
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;

  @media (min-width: $desktop) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
  }
}
